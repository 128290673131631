import React, { useEffect } from 'react'

export const wrapPage = ({ element }) => {
  return (
    <>
      {element}
    </>
  )
}

export default wrapPage
