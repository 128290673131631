
export const User = (state = {}, action) => {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case 'RECEIVE_PROFILE':
      newState = action.data
      return newState
    default:
      return state
  }
}

