import { AppsFlyer, AFConstants } from 'appsflyer-capacitor-plugin'
import { Capacitor } from '@capacitor/core'

import { setAppsFlyerID } from 'actions/User'

export const initAppsFlyer = ({ email, successCallback }) => {
  if (!Capacitor.isNativePlatform()) return

  const afConfig = {
    appID: '1589977418', // replace with your app ID.
    devKey: 'qgFJy4BFDhLJz4G9P5ZoLH', // replace with your dev key.
    isDebug: __DEV__,
    waitForATTUserAuthorization: 10, // for iOS 14 and higher
    registerConversionListener: true,
  }

  AppsFlyer.initSDK(afConfig).then(() => {
    AppsFlyer.setCustomerUserId({ cuid: email })

    AppsFlyer.getAppsFlyerUID().then(async res => {
      await setAppsFlyerID({ appsFlyerId: res.uid, platform: Capacitor.getPlatform() })
      trackAppsFlyerEvent({ eventName: 'af_login' })
      successCallback()
      window.analytics.track('AppsFlyer UID received', res)
    })

    AppsFlyer.addListener(AFConstants.CONVERSION_CALLBACK, event => {
      try {
        window.analytics.track('AppsFlyer conversionCallback listener', event)
      } catch {
        //
      }
    })
  })
}

export const trackAppsFlyerEvent = (data) => {
  try {
    AppsFlyer.logEvent(data)
  } catch {
    //
  }
}
