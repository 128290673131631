import { NativeBiometric } from 'capacitor-native-biometric'

export const biometricCheck = ({ successCallback, failureCallback, unavailableCallback }) => {
  NativeBiometric.isAvailable().then(result => {
    if (result.isAvailable) {
      NativeBiometric.verifyIdentity({
        reason: 'This is an additional layer of security to keep your account safe.',
        title: 'Biometric authentication',
        subtitle: 'This is an additional layer of security to keep your account safe.',
      }).then(successCallback, failureCallback)
    } else {
      unavailableCallback()
    }
  }).catch(() => {
    unavailableCallback()
  })

}
