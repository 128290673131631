import { Theme } from '_appSetup'
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/styles'

import { store } from 'reduxRoot'

export const wrapWithProvider = ({ element }) => {
  return (
    <ThemeProvider theme={Theme}>
      <Provider store={store}>
        {element}
      </Provider>
    </ThemeProvider>
  )
}

export default wrapWithProvider
