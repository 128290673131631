import { createMuiTheme } from '@material-ui/core/styles'

// NOTE check this https://material-ui.com/customization/default-theme/

const modernFonts = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',')

const theme = createMuiTheme({
  mixins: {
    toolbar: {
      minHeight: 60,
      backgroundColor: '#fff',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)',
    },
  },
  palette: {
    primary: { main: '#1A3066', light: '#EDF5FF' },
    secondary: {
      main: '#1A3066',
      lightTransparent: 'rgba(255, 181, 78, 0.25)',
      veryLightTransparent: 'rgba(255, 181, 78, 0.15)',
    },
    tertiary: { main: '#1A3066' },
  },
  typography: {
    allVariants: {
      color: '#1A3066',
    },
    fontFamily: `Inter,${modernFonts}`,
    fontSize: 13,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  absolute: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  circle: (side) => ({
    width: side,
    height: side,
    borderRadius: side / 2,
  }),
  row: () => ({
    display: 'flex',
    flexDirection: 'row',
  }),
  overrides: {
    MuiButton: {
      root: {
        margin: 10,
        textTransform: 'initial',
        fontSize: 14,
      },
      contained: {
        backgroundColor: '#c00',
        '&:hover': {
          backgroundColor: '#00c',
        },
        disableRipple: true, // No more ripple, on the whole application 💣!
        borderRadius: 5,
      },
      containedSecondary: {
        color: '#fff',
      },
      outlined: {
        borderRadius: 5,
      },
    },
    MuiContainer: {
      root: {
        padding: '0 !important',
      },
      maxWidthLg: {
        maxWidth: 'none !important',
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: 'none',
      },
    },
  },
})

export default { ...theme, isMobile: theme.breakpoints.down('sm') }
