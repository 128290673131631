import { store } from 'reduxRoot'
import firebase from 'gatsby-plugin-firebase'
import { navigate, Link } from 'gatsby'

export const fetchUserData = async ({
  uid,
  loadIntoRedux = true,
}) => {
  if (!uid) uid = firebase.auth().currentUser?.uid
  if (!uid) return

  // DEV_ONLY
  // if (__DEV__) uid = 'YLO5BpsKE3OcZ36aF65v6jCh6ug2'

  const serverFunc = firebase.functions().httpsCallable('fetchUserDataNew')
  const result = await serverFunc({ uid })

  const userData = result.data.result

  if (loadIntoRedux) receiveProfile(userData)

  return userData
}

export function receiveProfile(data) {
  store.dispatch({ type: 'RECEIVE_PROFILE', data })
}

export const setAppsFlyerID = async ({ appsFlyerId, platform }) => {
  const serverFunc = firebase.functions().httpsCallable('setAppsFlyerId')
  await serverFunc({ appsFlyerId, platform })
}

export function logout() {
  store.dispatch({ type: 'LOGOUT' })
}

export const signOut = () => {
  firebase.auth().signOut()
  logout()
  navigate('/login')
}
