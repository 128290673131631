import 'firebase/database'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/analytics'

import firebase from 'gatsby-plugin-firebase'
import { navigate } from 'gatsby'
import { fetchUserData, signOut } from 'actions/User'
import { Dialog } from '@capacitor/dialog'
import { store } from 'reduxRoot'
import moment from 'moment'
import { initAppsFlyer } from 'utils/analytics'

import { biometricCheck } from './src/pages/profile/_biometrics'

import { DOWN_FOR_MAINTENANCE } from '_appSetup/Constants'
import { App } from '@capacitor/app'

import wrapRoot from './wrapRoot'
import wrapPage from './wrapPage'

export const wrapRootElement = wrapRoot
export const wrapPageElement = wrapPage

let firstLoginDone = false
let lastActive = moment().toISOString()

const autologin = async (
  user = firebase.auth().currentUser,
  pathOverride = false, // needed if opening from background for biometrics to show
) => {

  firstLoginDone = true

  if (DOWN_FOR_MAINTENANCE) navigate('/maintenance')

  const userIsAuthenticated = user?.emailVerified
  if (!userIsAuthenticated) {
    firebase.auth().signOut()
  } else {
    if (window.analytics) window.analytics.identify(user?.email)
  }


  const path = typeof window !== 'undefined' ? window.location.pathname.split('/').filter(e => e.length) : '#'
  const openedNotLoggedInPath = ['login', 'signup'].includes(path[0])

  // redirect when not logged in
  if (!openedNotLoggedInPath && !userIsAuthenticated) {
    navigate('/login')
    return
  }

  if (user?.email) initAppsFlyer({ email: user.email })

  const UserData = await fetchUserData({})

  // redirect to the dashboard if on a non-dashboard path
  if ((!path[0] || openedNotLoggedInPath || pathOverride || UserData?.accountClosed) && userIsAuthenticated) {

    const successDestination = UserData?.accountClosed ? '/closed' : '/dashboard'

    if (UserData.BiometricEnabled) {
      biometricCheck({
        successCallback: () => {
          navigate(successDestination)
        },
        failureCallback: (error) => {
          Dialog.alert({
            title: 'Error',
            message: 'Biometric verification failed. Please sign in again.',
          })
          signOut()
        },
        unavailableCallback: () => {
          navigate(successDestination)
        },
      })
    } else {
      navigate(successDestination)
    }
  }

  App.addListener('appStateChange', ({ isActive }) => {
    if (!(store.getState().User?.BiometricEnabled)) return

    // FaceID causes inactive status, so we need another check to make sure the user is not re-checked all the time
    if (isActive) {
      if (moment().isAfter(moment(lastActive).add(10, 'minutes'))) {
        App.removeAllListeners()
        navigate('/')
        autologin(undefined, true)
      }
    } else {
      lastActive = moment().toISOString()
    }
  })

}

export const onClientEntry = () => {
  if (typeof window !== 'undefined') firebase.analytics()

  firebase.auth().onAuthStateChanged((user) => {
    if (!firstLoginDone) {
      autologin(user) // if the path matches the auth state we don't need to always redirect
    }
  })
}
