// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-closed-js": () => import("./../../../src/pages/closed.js" /* webpackChunkName: "component---src-pages-closed-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-deposit-index-js": () => import("./../../../src/pages/deposit/index.js" /* webpackChunkName: "component---src-pages-deposit-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */),
  "component---src-pages-referrals-index-js": () => import("./../../../src/pages/referrals/index.js" /* webpackChunkName: "component---src-pages-referrals-index-js" */),
  "component---src-pages-signup-index-js": () => import("./../../../src/pages/signup/index.js" /* webpackChunkName: "component---src-pages-signup-index-js" */),
  "component---src-pages-signup-set-pw-js": () => import("./../../../src/pages/signup/setPW.js" /* webpackChunkName: "component---src-pages-signup-set-pw-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-tax-js": () => import("./../../../src/pages/tax.js" /* webpackChunkName: "component---src-pages-tax-js" */),
  "component---src-pages-withdraw-index-js": () => import("./../../../src/pages/withdraw/index.js" /* webpackChunkName: "component---src-pages-withdraw-index-js" */),
  "component---src-pages-withdraw-luna-index-js": () => import("./../../../src/pages/withdrawLuna/index.js" /* webpackChunkName: "component---src-pages-withdraw-luna-index-js" */)
}

